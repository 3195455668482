import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6389f209 = () => interopDefault(import('../pages/a-propos.vue' /* webpackChunkName: "pages/a-propos" */))
const _dbab2908 = () => interopDefault(import('../pages/actu/index.vue' /* webpackChunkName: "pages/actu/index" */))
const _79b33d7a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _df186b98 = () => interopDefault(import('../pages/actu/_slug.vue' /* webpackChunkName: "pages/actu/_slug" */))
const _2e1c0a64 = () => interopDefault(import('../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _196974d3 = () => interopDefault(import('../pages/auteur/_slug.vue' /* webpackChunkName: "pages/auteur/_slug" */))
const _73fb32e4 = () => interopDefault(import('../pages/definition/_slug.vue' /* webpackChunkName: "pages/definition/_slug" */))
const _35a56acd = () => interopDefault(import('../pages/whaleactivity/_slug.vue' /* webpackChunkName: "pages/whaleactivity/_slug" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-propos",
    component: _6389f209,
    name: "a-propos"
  }, {
    path: "/actu",
    component: _dbab2908,
    name: "actu"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact"
  }, {
    path: "/actu/:slug",
    component: _df186b98,
    name: "actu-slug"
  }, {
    path: "/articles/:slug?",
    component: _2e1c0a64,
    name: "articles-slug"
  }, {
    path: "/auteur/:slug?",
    component: _196974d3,
    name: "auteur-slug"
  }, {
    path: "/definition/:slug?",
    component: _73fb32e4,
    name: "definition-slug"
  }, {
    path: "/whaleactivity/:slug?",
    component: _35a56acd,
    name: "whaleactivity-slug"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
