<template>
    <default-layout>
      <nuxt />
    </default-layout>
</template>

<script>
import DefaultLayout from "../components/DefaultLayout.vue"
export default {
    components: {
       DefaultLayout
    }
};
</script>