import Vue from 'vue';

import {
  NavbarPlugin,
  LayoutPlugin,
  ButtonPlugin,
  VBTooltipPlugin
} from 'bootstrap-vue';

Vue.use(NavbarPlugin);
Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);

Vue.use(VBTooltipPlugin);
